.page-container {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.page-buttons {
    display: flex;
    flex-direction: column;
}

.game-area {
    position: relative;
    height: 60vh;
    width: 60vh;
    min-height: 500px;
    min-width: 500px;
    background-color: black;
    margin-bottom: 50px;
}

.grid {
    background-color: red;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.grid-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.grid-tile {
    position: relative;
    background-color: grey;
    margin: 5px;
    min-height: 20px;
    min-width: 20px;
    flex-grow: 1;
    cursor: pointer;
    color: black;
}

.grid-tile.open {
    background-color: white;
    display: flex;
    cursor: auto;
}

.grid-tile.flagged {
    background-color: orange;
    cursor: auto;
}

.grid-tile > div {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.grid-tile.hidden > div {
    display: none;
}

.grid-tile.flagged > div {
    display: none;
}

.grid-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
    z-index: 5;
    background: transparent;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.grid-overlay.show {
    display: flex;
}

.grid-overlay > div {
    background-color: #282c34;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
}
