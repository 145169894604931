.container { 
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cadeauContainer {
    display: flex;
    width: 100%;
    max-width: 500px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.present {
    width: 100%;
    height: auto;
}

.video {
    width: 90vw;
    height: 400px;
}

.copyPasta {
    box-sizing: border-box;
    margin: 15px;
}
